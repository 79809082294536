.container {
  position: relative;
  z-index: 1;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background: linear-gradient(135deg, #2c3e50, #3498db);
  overflow: hidden;
  display: flex;
  align-items: center;
}
.text-center {
  color: white;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  animation: scrollText 10s linear infinite; 
}

.text-center span {
    color: orange;
  }

@keyframes scrollText {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
